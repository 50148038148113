import { Box, Group, Text, Title } from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import {
  IconSquareRoundedNumber1,
  IconSquareRoundedNumber2,
  IconSquareRoundedNumber3,
  IconSquareRoundedNumber4,
} from "@tabler/icons-react";
import "./EvContent.css";

const EvContent = () => {
  return (
    <div className="content-comp-div">
      <Box className="content-box border">
        <Title className="content-title">Affordable, Sustainable</Title>
        <Text className="content-txt">
          Our commitment: Enabling sustainable, affordable miles for all EV
          Pioneers.
        </Text>
        <Text className="content-txt">
          As ad revenue rises, we aim to gradually reduce charging rates, which
          will go a long way to encourage green mobility uptake.
        </Text>
        <Text className="content-txt">
          Join us in the collective journey towards a greener future.
        </Text>
      </Box>
      <Box className="content-box top-margin">
        <Title className="content-title italic">Be a part of the journey</Title>
        <Text className="content-subtitle">Download Our App</Text>
        <Group justify="center" className="ev-app-grp" flex="center" gap="0">
          <Box className="ev-app-box left">
            <a
              href="https://apps.apple.com/us/app/sfa-ev-charging/id6475045158"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://res.cloudinary.com/dwectnni1/image/upload/v1703576957/Website/SFA/Images/Website2.0/EV%20Pioneers/apple_wpvmzk.png"
                alt="apple"
                className="ev-app-img"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.sfasustainabilityforall.evcharging"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://res.cloudinary.com/dwectnni1/image/upload/v1703576958/Website/SFA/Images/Website2.0/EV%20Pioneers/google_ublcgp.png"
                alt="google"
                className="ev-app-img"
              />
            </a>
          </Box>
          <Box className="ev-app-box right">
            <img
              src="https://res.cloudinary.com/dwectnni1/image/upload/v1709548545/Website/SFA/Images/Website2.0/EV%20Pioneers/Smartphone_with_lightning_logo_ybtwo9.png"
              alt="apple"
              className="ev-app-img-phone"
            />
          </Box>
        </Group>
      </Box>
      <Box className="content-box top-margin border">
        <Title className="content-title">Reserve up to 7 days in advance</Title>
        <img
          src="https://res.cloudinary.com/dwectnni1/image/upload/v1709884145/Website/SFA/Images/Website2.0/EV%20Pioneers/smartphone_with_app_u7pk90.png"
          alt="app-img"
          className="app-img"
        />
        <Text className="content-txt">
          Our innovative CMS allows our Pioneers to reserve charging time slots
          up to 7 days in advance, making it more convenient for trip plannings
          by ensuring charger availability.
        </Text>
      </Box>
      <Box className="content-box top-margin">
        <Title className="content-title">How it Works</Title>
        <Carousel
          // withIndicators
          className="ev-carousel"
          loop
          classNames={{
            indicator: "ev-carousel-indicator",
          }}
        >
          <Carousel.Slide>
            <Box className="ev-content-box">
              <IconSquareRoundedNumber1 className="ev-content-icon" size="26" />
              <Title className="content-subtitle">Park & Plug</Title>
              <img
                src="https://res.cloudinary.com/dwectnni1/image/upload/v1710405117/Website/SFA/Images/Website2.0/EV%20Pioneers/plug_in_dw9kus.jpg"
                alt="plug"
                className="ev-content-img"
              />
              <Text className="content-txt">
                Pioneer reaches charging bay, removes nozzle from charger slot
                and plugs it into EV charging port.
              </Text>
            </Box>
          </Carousel.Slide>
          <Carousel.Slide>
            <Box className="ev-content-box">
              <IconSquareRoundedNumber2 className="ev-content-icon" size="26" />
              <Title className="content-subtitle">Activate & Charge</Title>
              <img
                src="https://res.cloudinary.com/dwectnni1/image/upload/v1709884794/Website/SFA/Images/Website2.0/EV%20Pioneers/pay_and_charge_djai1k.png"
                alt="plug"
                className="ev-content-img"
              />
              <Text className="content-txt">
                Pioneer scans QR code shown on charger to activate charging
                session.
              </Text>
            </Box>
          </Carousel.Slide>
          <Carousel.Slide>
            <Box className="ev-content-box">
              <IconSquareRoundedNumber3 className="ev-content-icon" size="26" />
              <Title className="content-subtitle">Unplug & Complete</Title>
              <img
                src="https://res.cloudinary.com/dwectnni1/image/upload/v1710405156/Website/SFA/Images/Website2.0/EV%20Pioneers/returning_charging_nozzle_sgljvy.png"
                alt="plug"
                className="ev-content-img"
              />
              <Text className="content-txt ev">
                Pioneer removes charging nozzle and returns it to original
                charger slot, with cables put back neatly.
              </Text>
            </Box>
          </Carousel.Slide>
          <Carousel.Slide>
            <Box className="ev-content-box">
              <IconSquareRoundedNumber4 className="ev-content-icon" size="26" />
              <Title className="content-subtitle ev">
                {/* <IconSquareRoundedNumber4
                  className="ev-content-icon"
                  size="30"
                /> */}
                Continue Electrifying
              </Title>
              <img
                src="https://res.cloudinary.com/dwectnni1/image/upload/v1710405150/Website/SFA/Images/Website2.0/EV%20Pioneers/EV_image_duypeb.png"
                alt="plug"
                className="ev-content-img"
              />
              <Text className="content-txt">
                To ensure good EV charging etiquette, idle fees may occur if
                vehicle remains idle in charging bay after charging session is
                completed.
              </Text>
            </Box>
          </Carousel.Slide>
        </Carousel>
        <Group className="ev-grid" justify="centered"></Group>
      </Box>
    </div>
  );
};

export default EvContent;
