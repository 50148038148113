import "./Top.css";
import { IconChevronCompactUp } from "@tabler/icons-react";

const Top = () => {
  return (
    <div
      className={`top-div`}
      onClick={() =>
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      }
    >
      <IconChevronCompactUp size="15" color="green" className="top-arrow" />
      <img
        src="https://res.cloudinary.com/dwectnni1/image/upload/v1695802791/Website/SFA/Images/Adhoc/leaf_orvbjs.png"
        alt="leaf"
        className="to-top-img"
      />
    </div>
  );
};

export default Top;
