import { Burger, Group } from "@mantine/core";

import "./Header.css";
import { useState } from "react";
import useScrollDirection from "../../hooks/useScrollDirection";
import NavModal from "./NavModal";

const Header = () => {
  const scrollDirection = useScrollDirection();

  const [openedDrawer, setOpenedDrawer] = useState(false);

  //drawer open close handlers
  const openDrawer = () => setOpenedDrawer(true);
  const closeDrawer = () => setOpenedDrawer(false);

  return (
    <div
      className={`header-div ${scrollDirection === "down" ? "hide" : "show"}`}
    >
      <NavModal opened={openedDrawer} close={closeDrawer} />
      <Group>
        <a href="/">
          <img
            src="https://res.cloudinary.com/dwectnni1/image/upload/v1703145365/Website/SFA/Images/Website2.0/Copy_of_SFA_Logo_Green_Circle_ex4va7.png"
            alt="company-logo"
            id="header-logo"
          />
        </a>
        <Burger
          opened={openedDrawer}
          className="header-burger"
          color="#ffffff"
          onClick={openDrawer}
        />
      </Group>
    </div>
  );
};

export default Header;
