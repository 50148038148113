import useScreenSize from "../../hooks/useScreenSize";

const AboutImg = () => {
  const screenSize = useScreenSize();

  return (
    <div
      className="top-img-comp"
      style={{ height: `${screenSize.height - 90}px` }}
    >
      <div className="top-img-div">
        {/* <img
          src="https://res.cloudinary.com/dwectnni1/image/upload/v1703145363/Website/SFA/Images/Website2.0/About%20SFA/DALL_E_2023-12-18_10.50.45_-_A_company_brand_image_for__Sustainability_for_All__emphasizing_eco-friendly_and_inclusive_values._The_design_should_be_modern_and_engaging_symbolizi_fofw17.png"
          alt="top-img"
          className="top-img location"
        /> */}
      </div>
      <div className="top-img-title">Sustainability For All</div>
    </div>
  );
};

export default AboutImg;
