import { Box, Drawer, Group, NavLink } from "@mantine/core";
import {
  IconBulb,
  IconHeartHandshake,
  IconInfoCircle,
  IconPhone,
  IconX,
} from "@tabler/icons-react";
import "./NavModal.css";

const NavModal = ({ opened, close }) => {
  return (
    <Drawer.Root
      opened={opened}
      onClose={close}
      className="nav-modal"
      zIndex="1000"
      // fullScreen
      // radius={0}
      // transitionProps={{ transition: "fade", duration: 500 }}
      position="right"
    >
      <Drawer.Overlay className="nav-modal-overlay" />
      <Drawer.Content>
        <Drawer.Body className="nav-modal-content">
          <Group justify="right">
            <IconX
              size="35"
              className="nav-modal-close"
              onClick={close}
              color="#beffcd"
            />
          </Group>
          <Group justify="center" className="nav-modal-logo-grp">
            <a className="nav-modal-logo-border" href="/">
              .
            </a>
            <img
              src="https://res.cloudinary.com/dwectnni1/image/upload/v1703145365/Website/SFA/Images/Website2.0/Copy_of_SFA_Logo_Green_Circle_ex4va7.png"
              alt="company-logo"
              id="nav-modal-logo"
            />
          </Group>
          <Box className="nav-modal-box">
            <NavLink
              label="Supporting Green Mobility"
              className="nav-modal-links main"
              childrenOffset={0}
              icon={
                <IconHeartHandshake color="#beffcd" className="nav-link-icon" />
              }
            >
              <a className="link-anchor" href="/">
                <NavLink
                  label="Charger-Out-of-Home"
                  className="nav-modal-links sub"
                />
              </a>
              <a className="link-anchor" href="/location-partner">
                <NavLink
                  label="Location Partners"
                  className="nav-modal-links sub"
                />
              </a>
              <a className="link-anchor" href="/ad-partner">
                <NavLink
                  label="Advertising Partners"
                  className="nav-modal-links sub"
                />
              </a>
              <a className="link-anchor" href="/ev-pioneers">
                <NavLink label="EV Pioneers" className="nav-modal-links sub" />
              </a>
            </NavLink>
            <a className="link-anchor" href="/about-us">
              <NavLink
                label="About Us"
                className="nav-modal-links main"
                icon={
                  <IconInfoCircle color="#beffcd" className="nav-link-icon" />
                }
              />
            </a>
            <a className="link-anchor" href="/future-initiatives">
              <NavLink
                label="Our Future Initiatives"
                className="nav-modal-links main"
                icon={<IconBulb color="#beffcd" className="nav-link-icon" />}
              />
            </a>
            <a className="link-anchor" href="/contact">
              <NavLink
                label="Contact"
                className="nav-modal-links main"
                icon={<IconPhone color="#beffcd" className="nav-link-icon" />}
              />
            </a>
          </Box>
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};

export default NavModal;
