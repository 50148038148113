import AdContent from "../components/adPartner/AdContent";
import AdImg from "../components/adPartner/AdImg";

const AdPartner = () => {
  return (
    <div className="page-div">
      <AdImg />
      <AdContent />
    </div>
  );
};

export default AdPartner;
