import useScreenSize from "../../hooks/useScreenSize";
// import cooh from "../../images/cooh.png";

const HomeImg = () => {
  const pageAtTop = () => {
    if (window.pageYOffset === 0) {
      return true;
    } else {
      return false;
    }
  };

  const screenSize = useScreenSize();

  return (
    <div
      className="top-img-comp"
      style={{ height: `${screenSize.height - 90}px` }}
    >
      <div className="top-img-div">
        {/* <img src={cooh} alt="top-img" className="top-img" /> */}
      </div>
      {/* <div className="top-img-title">C-O-O-H</div> */}
      <div className="top-img-title">C-O-O-H</div>
      <div className={`top-img-scroll-down-div ${!pageAtTop() ? "hide" : ""}`}>
        <img
          src="https://res.cloudinary.com/dwectnni1/image/upload/v1703148072/Website/SFA/Images/Website2.0/adhoc/down_vusoot.gif"
          alt="down"
          className="top-img-scroll-down"
        />
      </div>
    </div>
  );
};

export default HomeImg;
