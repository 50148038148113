import { Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import Header from "./components/headerFooter/Header";
import "./App.css";
import Footer from "./components/headerFooter/Footer";
import LocationPartner from "./pages/LocationPartner";
import Future from "./pages/Future";
import Contact from "./pages/Contact";
import Top from "./components/adhoc/Top";
import AdPartner from "./pages/AdPartner";
import EvPioneers from "./pages/EvPioneers";
import About from "./pages/About";
import "@mantine/core/styles.css";
import "@mantine/carousel/styles.css";

const App = () => {
  // const Wrapper = ({ children }) => {
  //   const location = useLocation();
  //   useLayoutEffect(() => {
  //     window.scrollTo(0, 0);
  //   }, [location.pathname]);
  //   return children;
  // };
  const url = window.location.href;

  const renderBg = () => {
    if (url.includes("location-partner")) {
      return require("./images/lp.png");
    } else if (url.includes("ad-partner")) {
      return require("./images/ap.png");
    } else if (url.includes("ev-pioneers")) {
      return require("./images/ev.png");
    } else if (url.includes("future-initiatives")) {
      return require("./images/fi.png");
    } else {
      return require("./images/cooh.png");
    }
  };

  return (
    <div className="app-div">
      <img className="app-bg" src={renderBg()} alt="bg" />
      <Header />
      <div className="app-body">
        {/* <Wrapper> */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/location-partner" element={<LocationPartner />} />
          <Route exact path="/ad-partner" element={<AdPartner />} />
          <Route exact path="/ev-pioneers" element={<EvPioneers />} />
          <Route exact path="/about-us" element={<About />} />
          <Route exact path="/future-initiatives" element={<Future />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
        {/* </Wrapper> */}
        <Top />
      </div>
      <Footer />
    </div>
  );
};

export default App;
