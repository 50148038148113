import LocationContent from "../components/locationPartner/LocationContent";
import LocationImg from "../components/locationPartner/LocationImg";

const LocationPartner = () => {
  return (
    <div className="page-div">
      <LocationImg />
      <LocationContent />
    </div>
  );
};

export default LocationPartner;
