import { Group, Text, Title } from "@mantine/core";

import "./COOH.css";

const COOH = () => {
  return (
    <div>
      {/* <div className="cooh-empty-div"></div> */}
      <div className="content-comp-div">
        {/* <Title className="cooh-title">C-O-O-H</Title> */}
        <Group justify="center" className="cooh-img-grp" gap="0">
          <img
            src="https://res.cloudinary.com/dwectnni1/image/upload/v1703145364/Website/SFA/Images/Website2.0/COOH/EV_Charging_Stand_with_integrated_digital_signage_vtamah.png"
            alt="ev-charger"
            className="cooh-img"
          />
          <img
            src="https://res.cloudinary.com/dwectnni1/image/upload/v1703145365/Website/SFA/Images/Website2.0/COOH/EV_Charging_Stand_with_integrated_digital_signage_1_vd6gty.png"
            alt="ev-charger"
            className="cooh-img"
          />
        </Group>
        <div className="content-box border top-margin">
          <Title className="content-title cooh">Charger-Out-Of-Home</Title>
          <Text className="content-subtitle cooh">Ev Charging x DOOH</Text>
          <Text className="content-txt">
            In the convergence of seemingly disparate domains, innovation
            thrives, allowing us to maximize the potential of each, reaffirming
            our steadfast commitment to advancing sustainability through
            innovative solutions.
          </Text>
          <Text className="content-txt">
            By seamlessly integrating{" "}
            <span className="under-bold">EV Charging</span> and{" "}
            <span className="under-bold">Digital-Out-Of-Home (DOOH)</span>{" "}
            marketing, we aspire to champion the adoption of green mobility
            solutions. Our mission is to foster a future where eco-conscious
            transportation takes center stage, by becoming the N°1 choice for{" "}
            <span className="under-bold">Charging, Out-Of-Home</span>.
          </Text>
        </div>
      </div>
    </div>
  );
};

export default COOH;
