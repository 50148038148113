import HomeImg from "../components/home/HomeImg";
import "./Page.css";
import COOH from "../components/home/COOH";
import HomeContent from "../components/home/HomeContent";

const Home = () => {
  return (
    <div className="page-div">
      <HomeImg />
      <COOH />
      <HomeContent />
    </div>
  );
};

export default Home;
