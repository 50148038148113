import useScreenSize from "../../hooks/useScreenSize";
// import ev from "../../images/ev.png";

const EvImg = () => {
  const screenSize = useScreenSize();

  return (
    <div
      className="top-img-comp"
      style={{ height: `${screenSize.height - 90}px` }}
    >
      <div className="top-img-div">
        {/* <img src={ev} alt="top-img" className="top-img location" /> */}
      </div>
      <div className="top-img-title">EV PIONEERS</div>
    </div>
  );
};

export default EvImg;
