import { Box, Group, Title } from "@mantine/core";
import "./Footer.css";
import { IconBrandWhatsapp } from "@tabler/icons-react";
import { IconMail } from "@tabler/icons-react";
import refundPolicy from "../../pdf/Refund_policy.pdf";

const Footer = () => {
  return (
    <div className="footer-div">
      <Group justify="center">
        <Box className="contact-box">
          <Box className="contact-box-1">
            <Title className="contact-link-title">Contact</Title>
            <a className="contact-anchor" href="/contact">
              <p className="contact-link-1">Contact Us</p>
            </a>
            <a
              className="contact-anchor"
              href="https://calendar.app.google/cTqLNBfuYyjninBk7"
              target="_blank"
              rel="noreferrer"
            >
              <p className="contact-link-1">Book an Online Meeting</p>
            </a>
            <a
              className="contact-anchor"
              href="https://calendar.app.google/ZRtWZUgQp2sgSAhB9"
              target="_blank"
              rel="noreferrer"
            >
              <p className="contact-link-1">Book a Site Visit</p>
            </a>
            <a
              className="contact-anchor"
              href={refundPolicy}
              target="_blank"
              rel="noreferrer"
            >
              <p className="contact-link-1">Refund Policy</p>
            </a>
          </Box>
          <Group justify="center">
            <IconBrandWhatsapp
              size="40"
              className="contact-icon"
              onClick={() =>
                window.open(
                  "https://wa.me/+60106680854",
                  "_blank",
                  "noreferrer"
                )
              }
            />
            <IconMail
              size="40"
              className="contact-icon"
              onClick={() =>
                window.open("mailto:general@sfa-ss.com", "_blank", "noreferrer")
              }
            />
          </Group>
        </Box>
        {/* <div className="add-box">
          <IconMapPin />
          <Text className="add-text">
            10, Lorong Samarinda 35D
            <br />
            Taman Mesra, Kampung Jawa
            <br />
            41000, Klang
            <br />
            Selangor, Malaysia
          </Text>
        </div> */}
      </Group>
      <Group justify="center" className="footer-copyright-grp">
        <img
          src="https://res.cloudinary.com/dwectnni1/image/upload/v1703145365/Website/SFA/Images/Website2.0/Copy_of_SFA_Logo_Green_Circle_ex4va7.png"
          alt="logo"
          id="footer-logo"
        />
        <p id="footer-copyright">
          © {new Date().getFullYear()} SFA Sustainability Solutions Sdn. Bhd.
        </p>
      </Group>
    </div>
  );
};

export default Footer;
