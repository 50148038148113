import { Box, Button, Text, Title } from "@mantine/core";

import "./FutureContent.css";
import { IconArrowRight } from "@tabler/icons-react";

const FutureContent = () => {
  return (
    <div className="content-comp-div">
      {/* <Title className="content-title future">Our Future Initiatives</Title> */}
      <Box className="content-box top-margin border">
        <Title className="content-title">
          Embarking on the
          <br />
          #Sustainability Journey
        </Title>
        <Text className="content-txt">
          Doing our part to promote green mobility does not mean our job is
          done.
        </Text>
        <Text className="content-txt">
          We are always on the outlook for more opportunities to promote
          sustainability, and we are open to collaborate with like minded
          individuals / organizations to make the world greener.
        </Text>
      </Box>
      <Box className="content-box top-margin">
        <Title className="content-subtitle">
          Have an idea or a proposal beneficial to our planet?
        </Title>
        <Title className="content-subtitle" style={{ marginTop: "30px" }}>
          Contact us - and let us turn it into a reality together.
        </Title>
      </Box>
      <Box className="content-box top-margin">
        <Title className="content-title italic">
          Be at the forefront of change
        </Title>{" "}
        <a href="/contact" className="link-anchor">
          <Button
            className="contact-btn"
            radius="xl"
            rightSection={<IconArrowRight />}
          >
            CONTACT US
          </Button>
        </a>
      </Box>
    </div>
  );
};

export default FutureContent;
