import AboutContent from "../components/about/AboutContent";
import AboutImg from "../components/about/AboutImg";

const About = () => {
  return (
    <div className="page-div">
      <AboutImg />
      <AboutContent />
    </div>
  );
};

export default About;
