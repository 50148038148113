import {
  Box,
  Button,
  Group,
  Radio,
  Select,
  TextInput,
  Textarea,
  Title,
} from "@mantine/core";
import "./ContactForm.css";
import { useRef, useState } from "react";
import { states } from "../../lists/states";
import { notifications } from "@mantine/notifications";

import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const [input, setInput] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [topic, setTopic] = useState("");
  const [state, setState] = useState("");

  const form = useRef();

  const handleChange = (e, type) =>
    setInput({ ...input, [type]: e.target.value });

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("sfa-ss", "template_gpnf9aq", form.current, "5kVnD1jMHq7RHa6S_")
      .then(
        (result) => {
          console.log(result.text);
          setInput({ name: "", email: "", phone: "", message: "" });
          setTopic("");
          setState("");
          notifications.show({
            title: "Your email has been sent",
            color: "green",
            autoClose: "3000",
          });
        },
        (error) => {
          console.log(error.text);
          notifications.show({
            title: "An error has occured",
            message: "Please try again",
            color: "red",
          });
        }
      );
  };

  return (
    <Box className="content-box contact-form" id="contact-form">
      <Title className="content-title">Contact Form</Title>
      <Box className="contact-form-box">
        <form ref={form} onSubmit={sendEmail}>
          <TextInput
            label="Name"
            withAsterisk
            required
            type="text"
            className="contact-form-input"
            value={input.name}
            onChange={(e) => handleChange(e, "name")}
            name="user_name"
            radius="xl"
          />
          <TextInput
            label="E-mail"
            withAsterisk
            required
            type="email"
            className="contact-form-input"
            value={input.email}
            onChange={(e) => handleChange(e, "email")}
            name="user_email"
            radius="xl"
          />
          <TextInput
            label="Phone"
            withAsterisk
            required
            type="number"
            className="contact-form-input"
            value={input.phone}
            onChange={(e) => handleChange(e, "phone")}
            name="user_phone"
            radius="xl"
          />
          <Select
            label="State"
            withAsterisk
            data={states}
            className="contact-form-select"
            value={state}
            onChange={setState}
            required
            name="user_state"
            radius="xl"
            placeholder="Please select a state"
          />
          <Radio.Group
            label="Topic"
            value={topic}
            onChange={setTopic}
            withAsterisk
            required
            className="contact-form-radio-grp"
            styles={() => ({
              label: {
                color: "white",
                fontSize: "18px",
                marginLeft: "10px",
                marginBottom: "5px",
              },
            })}
            name="topic"
          >
            <Radio
              label="Location Partner"
              value="Location Partner"
              className="contact-form-radio"
            />
            <Radio
              label="Advertising Partner"
              value="Advertising Partner"
              className="contact-form-radio"
            />
            <Radio
              label="EV Pioneer"
              value="EV Pioneer"
              className="contact-form-radio"
            />
            <Radio
              label="Other Sustainability Projects"
              value="Other Sustainability Projects"
              className="contact-form-radio"
            />
          </Radio.Group>
          <Textarea
            label="Message"
            withAsterisk
            required
            type="text"
            className="contact-form-input"
            value={input.message}
            onChange={(e) => handleChange(e, "message")}
            autosize
            minRows={3}
            name="message"
            radius="xl"
          />
          <Group position="center">
            <Button
              className="contact-btn contact-form"
              radius="xl"
              type="submit"
            >
              Submit
            </Button>
          </Group>
        </form>
      </Box>
    </Box>
  );
};

export default ContactForm;
