import { Box, Button, Text, Title } from "@mantine/core";
import "./ContactUs.css";

const ContactUs = () => {
  return (
    <div className="content-comp-div">
      <Title className="content-title contact-us">Contact</Title>
      <Box className="content-box border top-margin contact-us">
        <Title className="content-title">Drop us a message</Title>
        <Text className="content-txt">
          Use the following contact form, or schedule a meeting/site visit with
          us.
        </Text>
        <Text className="content-txt">
          We will get back to you as soon as we can.
        </Text>
        <Text className="content-txt">
          Thank you in advance, for advancing the sustainability agenda.
        </Text>
        <Box className="contact-us-box">
          <Button
            className="contact-btn contact-us"
            radius="xl"
            onClick={() =>
              window.open(
                "https://calendar.app.google/cTqLNBfuYyjninBk7",
                "_blank",
                "noreferrer"
              )
            }
          >
            Book an Online Meeting
          </Button>
          <Button
            className="contact-btn contact-us"
            radius="xl"
            onClick={() =>
              window.open(
                "https://calendar.app.google/ZRtWZUgQp2sgSAhB9",
                "_blank",
                "noreferrer"
              )
            }
          >
            Book a Site Visit
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default ContactUs;
