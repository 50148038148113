import ContactForm from "../components/contact/ContactForm";
import ContactUs from "../components/contact/ContactUs";
import "./Page.css";

const Contact = () => {
  return (
    <div className="page-div">
      <ContactUs />
      <ContactForm />
    </div>
  );
};

export default Contact;
