import useScreenSize from "../../hooks/useScreenSize";
// import ap from "../../images/ap.png";

const AdImg = () => {
  const screenSize = useScreenSize();

  return (
    <div
      className="top-img-comp"
      style={{ height: `${screenSize.height - 90}px` }}
    >
      <div className="top-img-div">
        {/* <img
          // src="https://res.cloudinary.com/dwectnni1/image/upload/v1704176569/Website/SFA/Images/Website2.0/AP/DALL_E_2023-12-29_18.37.29_-_A_realistic_image_of_an_EV_charging_station_in_a_busy_mall_parking_lot_next_to_the_entrance._The_charging_stand_is_integrated_with_a_digital_display_t_mnjaa4.png"
          src={ap}
          alt="top-img"
          className="top-img full-width"
        /> */}
      </div>
      <div className="top-img-title">ADVERTISING PARTNER</div>
    </div>
  );
};

export default AdImg;
