import FutureContent from "../components/future/FutureContent";
import FutureImg from "../components/future/FutureImg";
// import Journey from "../components/future/Journey";
import Projects from "../components/future/Projects";
import "./Page.css";

const Future = () => {
  return (
    <div className="page-div">
      <FutureImg />
      <Projects />
      {/* <Journey /> */}
      <FutureContent />
    </div>
  );
};

export default Future;
