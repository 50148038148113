import { IconArrowRight } from "@tabler/icons-react";
import "./AboutContent.css";

import { Box, Button, Text, Title } from "@mantine/core";

const AboutContent = () => {
  return (
    <div className="content-comp-div">
      <Box className="content-box border">
        <Title className="content-title">The Grand Vision</Title>
        <Text className="content-txt">
          At SFA, we envision a future where sustainability drives every
          endeavor, and we're dedicated to realizing that vision.
        </Text>
        <Text className="content-txt">
          With our focus on financially viable solutions, we aim to create a
          world where everyone contributes to a greener, more prosperous planet.
        </Text>
        <Text className="content-txt">
          {`Join us as we pave the way to a sustainable future, `}
          <span className="under-bold">FOR ALL</span>.
        </Text>
      </Box>
      <Box className="content-box border top-margin">
        <Title className="content-title">Our Mission</Title>
        <Text className="content-txt">
          We are committed to relentless innovation, breaking financial barriers
          to sustainability solutions.
        </Text>
        <Text className="content-txt">
          More than a company, we're a catalyst for a brighter, greener future.
        </Text>
        <Text className="content-txt">
          Sustainability isn't a luxury; it's a lifestyle. Our innovative,
          affordable solutions empower everyone to embrace sustainability
          wholeheartedly.
        </Text>
      </Box>
      <Box className="content-box top-margin">
        <Title className="content-title italic">
          Join us on a journey to make sustainable living joyful and affordable
        </Title>
        <a href="/contact" className="link-anchor">
          <Button
            className="contact-btn"
            radius="xl"
            rightSection={<IconArrowRight />}
          >
            CONTACT US
          </Button>
        </a>
      </Box>
    </div>
  );
};

export default AboutContent;
