import useScreenSize from "../../hooks/useScreenSize";
import "./LocationImg.css";
// import lp from "../../images/lp.png";

const LocationImg = () => {
  const screenSize = useScreenSize();

  return (
    <div
      className="top-img-comp"
      style={{ height: `${screenSize.height - 90}px` }}
    >
      <div className="top-img-div">
        {/* <img src={lp} alt="top-img" className="top-img location" /> */}
      </div>
      <div className="top-img-title">LOCATION PARTNER</div>
    </div>
  );
};

export default LocationImg;
