import EvContent from "../components/EvPioneers/EvContent";
import EvImg from "../components/EvPioneers/EvImg";

const EvPioneers = () => {
  return (
    <div className="page-div">
      <EvImg />
      <EvContent />
    </div>
  );
};

export default EvPioneers;
