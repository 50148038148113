import { Box, Group } from "@mantine/core";
import "./Projects.css";
import { useNavigate } from "react-router-dom";

const Projects = () => {
  const navigate = useNavigate();

  return (
    <div className="content-comp-div">
      <div className="future-projects-timeline">
        <Group position="center" className="future-projects-grp">
          <Box className="future-projects-box left">
            Renewable Energy <div className="future-projects-btm-tri"></div>
          </Box>
          <Box className="future-projects-box right">
            Battery Energy Storage Solutions (BESS)
            <div className="future-projects-btm-tri"></div>
          </Box>
        </Group>
        <img
          src="https://res.cloudinary.com/dwectnni1/image/upload/v1703659706/Website/SFA/Images/Website2.0/Future%20Initiatives/future-1_hannum.png"
          alt="timeline-1"
          className="future-projects-img"
        />
        <Group position="center" className="future-projects-grp">
          <Box className="future-projects-box left">
            Household Waste-to-Energy (WtE)
            <div className="future-projects-top-tri"></div>
          </Box>
          <Box
            className="future-projects-box right navigate"
            onClick={() => navigate("/ev")}
          >
            Green Mobility
            <div className="future-projects-top-tri"></div>
          </Box>
        </Group>
        <img
          src="https://res.cloudinary.com/dwectnni1/image/upload/v1703659706/Website/SFA/Images/Website2.0/Future%20Initiatives/future-2_qr66wk.png"
          alt="timeline-2"
          className="future-projects-img timeline-2"
        />
        <Group position="center" className="future-projects-grp">
          <Box className="future-projects-box left">
            Carbon Capture, Utilisation & Storage (CCUS)
            <div className="future-projects-top-tri"></div>
          </Box>
          <Box className="future-projects-box right">
            Carbon Credit
            <div className="future-projects-top-tri"></div>
          </Box>
        </Group>
      </div>
    </div>
  );
};

export default Projects;
