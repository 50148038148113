import { Accordion, Box, Button, Text, Title } from "@mantine/core";
import "./AdContent.css";
import { IconArrowRight } from "@tabler/icons-react";

const AdContent = () => {
  const accordionContent = [
    {
      value: "Cost-effective advertising	",
      description: (
        <Text>
          At a fraction of the cost of traditional DOOH mediums
          <br />
          Take advantage of our incentives & discounts for businesses making an
          impact
        </Text>
      ),
    },
    {
      value: "Offline Presence",
      description: (
        <Text>Extra marketing channel for online-only business</Text>
      ),
    },
    {
      value: "High-End Reach",
      description: <Text>Target EV users with higher purchasing power</Text>,
    },
    {
      value: "Eco-Friendly Image",
      description: (
        <Text>
          Show potential customers your support of sustainability efforts
        </Text>
      ),
    },
    {
      value: "Enhanced Local Presence",
      description: (
        <Text>Be the talk of town when you appear on our novel COOH</Text>
      ),
    },
  ];

  const accordionItems = accordionContent.map((item) => (
    <Accordion.Item
      key={item.value}
      value={item.value}
      className="content-accordiong-item"
    >
      <Accordion.Control className="content-accordion-control">
        <Text className="content-accordion-title">{item.value}</Text>
      </Accordion.Control>
      <Accordion.Panel className="content-accordion-panel">
        {item.description}
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <div className="content-comp-div">
      <Box className="content-box border">
        <Title className="content-title">
          When Sustainability Meets Marketing
        </Title>
        <Text className="content-txt">
          Cost is a barrier for sustainability, especially for small businesses.
        </Text>
        <Text className="content-txt">
          <span className="under-bold">
            Advertise on our COOH network to promote sustainability and meet
            your marketing goals, regardless of your size.
          </span>
        </Text>
      </Box>
      <Box className="content-box border top-margin">
        <Title className="content-title">Benefits of our COOH</Title>
        <Accordion className="content-accordion" transitionDuration={500}>
          {accordionItems}
        </Accordion>
      </Box>
      <Box className="content-box top-margin">
        <Title className="content-title italic">
          Be a proud sponsor of electric miles
        </Title>
        <a className="link-anchor" href="/contact">
          <Button
            className="contact-btn"
            rightSection={<IconArrowRight />}
            radius="xl"
          >
            Advertise on COOH
          </Button>
        </a>
      </Box>
    </div>
  );
};

export default AdContent;
