import { Accordion, Box, Button, Text, Title } from "@mantine/core";
import "./LocationContent.css";
import { IconArrowRight } from "@tabler/icons-react";

const LocationContent = () => {
  const accordionContent = [
    {
      value: "Low upfront cost",
      description: (
        <Text>
          Worried about the initial CAPEX?
          <br />
          <br />
          Fret not with our Zero Capex option
        </Text>
      ),
    },
    {
      value: "Extra Revenue",
      description: <Text>Charging + Ad Revenue</Text>,
    },
    {
      value: "Flexible Ownership",
      description: (
        <Text>
          Want to take full ownership of future revenue streams from our COOH?
          <br />
          <br />
          Be our guest
        </Text>
      ),
    },
    {
      value: "Increased Foot Traffic",
      description: (
        <Text>
          Attract EV users with higher purchasing power and drive retail sales
          revenue
        </Text>
      ),
    },
    {
      value: "Increased Retail Dwell Time",
      description: <Text>Thanks to longer time needed for EV Charging</Text>,
    },
    {
      value: "Eco-Friendly Image",
      description: (
        <Text>
          Show off your unwavering support of innovations for sustainability
        </Text>
      ),
    },
  ];

  const accordionItems = accordionContent.map((item) => (
    <Accordion.Item
      key={item.value}
      value={item.value}
      className="content-accordiong-item"
    >
      <Accordion.Control className="content-accordion-control">
        <Text className="content-accordion-title">{item.value}</Text>
      </Accordion.Control>
      <Accordion.Panel className="content-accordion-panel">
        {item.description}
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <div className="content-comp-div">
      <Box className="location-box">
        <Title className="content-title location-content">
          Take advantage of the EV trend
        </Title>
        <Text className="content-txt">
          Transform your location into an EV hotspot, drawing in traffic like
          “gas” stations for EVs.
        </Text>
        <Text className="content-txt">
          Picture an electric 'fueling station' on your property, tailored for
          EV owners.
        </Text>
        <Text className="content-txt">
          <span className="under-bold">
            With the rise in EV ownership, capitalize on this opportunity. Make
            your site the go-to destination for EV users with dedicated charging
            stations.
          </span>
        </Text>
      </Box>
      <Box className="location-box mid">
        <Title className="content-title location-content">
          Benefits of our COOH
        </Title>
        <Accordion className="location-accordion">{accordionItems}</Accordion>
      </Box>
      <div className="location-box btm">
        <Title className="content-title location-content">
          Provide the Space,
          <br />
          And We Handle the Rest
        </Title>
        <div className="location-grid">
          <Box className="location-card">
            {/* <Card.Section> */}
            <img
              src="https://res.cloudinary.com/dwectnni1/image/upload/v1703145370/Website/SFA/Images/Website2.0/LP/Copy_of_DALL_E_2023-12-19_17.43.01_-_An_office_meeting_focused_on_consultation_for_the_installation_of_an_EV_charging_station_at_a_business_premise._In_the_foreground_a_detailed_architec_iyuu3r.png"
              alt="consult"
              className="location-card-img"
            />
            {/* </Card.Section> */}
            <Title className="content-subtitle location-content">
              Consultation
            </Title>
            <Text className="content-txt location-content">
              Identify suitable EV Charging Solution for your business
            </Text>
          </Box>
          <Box className="location-card">
            {/* <Card.Section> */}
            <img
              src="https://res.cloudinary.com/dwectnni1/image/upload/v1703145371/Website/SFA/Images/Website2.0/LP/Copy_of_DALL_E_2023-12-19_17.45.00_-_An_outdoor_scene_of_the_ongoing_installation_of_an_EV_charging_stand_at_a_business_location._In_the_center_a_team_of_workers_in_safety_gear_actively_nhwo1u.png"
              alt="consult"
              className="location-card-img"
            />
            {/* </Card.Section> */}
            <Title className="content-subtitle location-content">
              Installation
            </Title>
            <Text className="content-txt location-content">
              Install and commission EV Charging Points for operations
            </Text>
          </Box>
          <Box className="location-card">
            {/* <Card.Section> */}
            <img
              src="https://res.cloudinary.com/dwectnni1/image/upload/v1703145370/Website/SFA/Images/Website2.0/LP/Copy_of_DALL_E_2023-12-19_17.48.36_-_A_scene_showing_the_operations_and_maintenance_of_EV_charging_stations._The_setting_is_an_outdoor_EV_charging_station_area_with_multiple_charging_unit_rchubv.png"
              alt="consult"
              className="location-card-img"
            />
            {/* </Card.Section> */}
            <Title className="content-subtitle location-content">
              Operations & Maintenance
            </Title>
            <Text className="content-txt location-content">
              Automated daily operations and regular maintenance
            </Text>
          </Box>
        </div>
      </div>
      <Box className="location-box btm">
        <Title className="content-title italic location-content">
          Join our COOH network
        </Title>
        <a className="link-anchor location" href="/contact">
          <Button
            className="contact-btn host"
            rightSection={<IconArrowRight />}
            radius="xl"
          >
            HOST A COOH
          </Button>
        </a>
      </Box>
    </div>
  );
};

export default LocationContent;
