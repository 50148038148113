import { Box, Button, Text, Title } from "@mantine/core";
import "./HomeContent.css";
import { IconArrowRight } from "@tabler/icons-react";

const HomeContent = () => {
  return (
    <div className="content-comp-div">
      <div className="content-box">
        <Title className="content-title italic">
          Redefining EV Charging Together
        </Title>
        <div className="affordable-content-grid">
          <Box className="affordable-content-box">
            <Title className="content-subtitle affordable">
              LOCATION
              <br />
              PARTNER
            </Title>
            <Text className="content-txt affordable">
              Low upfront cost.
              <br />
              Extra income.
              <br />
              Attract EV owners.
              <br />
              Stand out from competitors.
            </Text>
            <Text className="content-txt affordable">
              What are you still waiting for?
            </Text>
            <a href="/location-partner" className="link-anchor affordable">
              <Button
                rightSection={<IconArrowRight />}
                className="affordable-content-btn"
                radius="xl"
                fullWidth
              >
                I want to host a COOH
              </Button>
            </a>
          </Box>
          <Box className="affordable-content-box">
            <Title className="content-subtitle affordable">
              ADVERTISING
              <br />
              PARTNER
            </Title>
            <Text className="content-txt affordable">
              Cost-effective advertising.
              <br />
              High-end reach.
              <br />
              Eco-friendly brand image.
              <br />
              Enhanced local presence.
            </Text>
            <Text className="content-txt affordable">
              Meet your marketing & sustainability goals.
            </Text>
            <a href="/ad-partner" className="link-anchor affordable">
              <Button
                rightSection={<IconArrowRight />}
                className="affordable-content-btn"
                radius="xl"
                fullWidth
              >
                Help me achieve my goals
              </Button>
            </a>
          </Box>
          <Box className="affordable-content-box">
            <Title className="content-subtitle affordable">
              EV
              <br />
              PIONEERS
            </Title>
            <Text className="content-txt affordable">
              Affordable rates.
              <br />
              7-day advanced reservation.
              <br />
              Expanding network.
              <br />
              Incentives & discounts.
            </Text>
            <Text className="content-txt affordable">
              Join us on our journey for sustainability.
            </Text>
            <a href="/ev-pioneers" className="link-anchor affordable">
              <Button
                rightSection={<IconArrowRight />}
                className="affordable-content-btn"
                radius="xl"
                fullWidth
              >
                Sign me up!
              </Button>
            </a>
          </Box>
        </div>
      </div>
      <img
        src="https://res.cloudinary.com/dwectnni1/image/upload/v1703660648/Website/SFA/Images/Website2.0/COOH/affordable_ocxh7l.png"
        alt="ev"
        className="affordable-img"
      />
      <div className="content-box border top-margin">
        <Title className="content-title">
          Affordable EV Charging,
          <br />
          <span className="content-title monotone">F</span>or{" "}
          <span className="content-title monotone">A</span>ll
        </Title>
        <Text className="content-txt">
          Our aim is to tackle the prevalent issue of elevated public EV
          charging costs associated with conventional infrastructure.
        </Text>
        <Text className="content-txt">
          Through leveraging advertising revenue, we make it our mission to
          reduce charging rates for end users, benefiting not only EV owners but
          also organizations eager to contribute to a sustainable future while
          meeting marketing needs.
        </Text>
      </div>
    </div>
  );
};

export default HomeContent;
